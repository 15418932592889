<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="w-100 cart">
		<div :class="['xs','sm'].includes(windowWidth) ? 'h-80' : 'h-55'" />
		<div
			:class="['xs','sm'].includes(windowWidth) ? 'py-5' : 'p-5'"
			class="border-0 bg-white mb-0">
			<!-- Back to store link -->
			<div
				class="row mx-auto mb-5 container">
				<div class="col-12">
					<a @click="$router.push({ name: 'PSStore' })">
						<div class="btn btn-link pointer text-decoration-none p-0">
							<div>
								<i class="fas fa-chevron-left mr-2" />
								<span class="font-weight-bold">{{ translate('back_to_store') }}</span>
							</div>
						</div>
					</a>
				</div>
			</div>
			<!-- Main product -->
			<div v-if="mainProductLoading">
				<is-loading
					:loading="mainProductLoading"
					:loading-label="translate('loading')"
					:no-data-label="translate('data_not_found')" />
			</div>
			<div v-else>
				<!-- Image / data -->
				<div
					:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'"
					class="row mx-auto container mb-5">
					<div
						class="col-12 col-md-6"
						:class="['xs','sm'].includes(windowWidth) ? '' : 'd-none'">
						<!-- Product name / price -->
						<p class="text-primary h1 bold-text-primary">
							{{ computedTitle }}
						</p>
						<p class="text-muted h3 font-weight-bold">
							{{ computedPrice }}
						</p>
						<div class="h6 text-muted">
							{{ computedPriceInfo }}
						</div>
						<div
							v-if="mainHasExchange"
							class="mx-auto exchange-text text-center medium d-flex align-items-end">
							{{ mainExchangePrice }}*
						</div>
						<div
							v-if="mainHasExchange"
							class="mx-auto exchange-text text-left small d-flex align-items-end">
							{{ translate('exchange_disclaimer') }}
						</div>
						<p class="text-muted h6 font-weight-bold">
							{{ computedPresentation }}
						</p>
						<hr>
					</div>
					<!-- Image Component -->
					<div
						class="col-12 col-md-6"
						:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'">
						<image-carousel
							:images="imgCarousel"
							:group-to-show="getGroupToShow"
							style="--product-primary-color: var(--theme-primary-color);"
							highlight-color="var(--theme-primary-color)"
							:enable-zoom="true" />
					</div>
					<!-- Product price/sizes/colors/qty -->
					<div class="col-12 col-md-6">
						<!-- Product name / price -->
						<div :class="['xs','sm'].includes(windowWidth) ? 'd-none' : ''">
							<p class="text-primary h1 bold-text-primary">
								{{ computedTitle }}
							</p>
							<!-- Prices -->
							<p class="text-muted h3 font-weight-bold">
								{{ computedPrice }}
							</p>
							<div class="h6 text-muted">
								{{ computedPriceInfo }}
							</div>
							<div
								v-if="mainHasExchange"
								class="mx-auto exchange-text text-center medium d-flex align-items-end">
								{{ mainExchangePrice }}*
							</div>
							<div
								v-if="mainHasExchange"
								class="mx-auto exchange-text text-left small d-flex align-items-end">
								{{ translate('exchange_disclaimer') }}
							</div>
							<p class="text-muted h6 font-weight-bold">
								{{ computedPresentation }}
							</p>
							<hr>
						</div>
						<!-- Color -->
						<option-select
							variant="color"
							:title="translate('color')"
							:options="getProductColors"
							:selected-option.sync="selectedProduct.color" />
						<!-- Size -->
						<option-select
							variant="size"
							:title="translate('size')"
							:options="getProductSizes"
							:selected-option.sync="selectedProduct.size" />
						<!-- Formats -->
						<option-select
							:title="translate('format')"
							:options="getProductFormats"
							:selected-option.sync="selectedProduct.format" />
						<!-- Event Type -->
						<option-select
							:title="translate('event_type')"
							:options="getEventType"
							:selected-option.sync="selectedProduct.event_type">
							<div class="row no-gutters">
								<div class="col">
									<p class="mt-2 mb-0 font-weight-bolder text-danger">
										{{ translate('ticket_not_refundable_disclaimer') }}
									</p>
								</div>
							</div>
						</option-select>
						<!-- Presentation -->
						<option-select
							:title="translate('presentations')"
							:options="getPresentations"
							:selected-option.sync="selectedProduct.presentations" />
						<!-- Flavors -->
						<option-select
							:title="translate('flavor')"
							:options="getFlavor"
							:selected-option.sync="selectedProduct.flavor" />
						<!-- Material Types -->
						<option-select
							:title="translate('material_type')"
							:options="getMaterialTypes"
							:selected-option.sync="selectedProduct.material_type" />
						<!-- Combinations -->
						<option-select
							:title="translate('flavor')"
							:options="getCombination"
							:selected-option.sync="selectedProduct.combination" />
						<!-- Individual Purchase -->
						<div
							v-if="isForIndividualPurchase"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ translate('product_for_individual_sale') }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Shipping Date Alert -->
						<div
							v-else-if="shippingDateAlert"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="info"
											class="mb-0 text-dark">
											{{ translate('shipping_date_alert') }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Minimun Purchase alert -->
						<div
							v-if="minPurchaseAlert"
							class="row my-3">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="warning"
											class="mb-0 text-dark">
											{{ minPurchaseAlertMsg }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Product out of stock -->
						<div
							v-if="productOutOfStock"
							class="row">
							<div class="col-md-7 col-sm-12 col-xs-12 align-self-center">
								<button
									class="mb-0 btn btn-block btn-disabled disabled">
									{{ translate(`out_of_stock`) }}
								</button>
							</div>
						</div>
						<!-- Combo Requirements -->
						<div
							v-if="isCombo && !isComboComplete"
							class="row">
							<div class="col">
								<div class="row no-gutters">
									<div class="col">
										<b-alert
											show
											variant="info"
											class="mb-0 text-dark">
											{{ productsMissingForCombo === 1 ? translate('combo_choose_one_more_product') : translate('combo_choose_x_more_product', { qty: productsMissingForCombo }) }}
										</b-alert>
									</div>
								</div>
							</div>
						</div>
						<!-- Product qty/add btn -->
						<div
							v-if="!productOutOfStock"
							:class="isCombo && !isComboComplete ? '' : 'pt-3'"
							class="row my-3">
							<div class="align-self-center w-auto mb-3">
								<number-input-spinner
									v-model="selectedProduct.quantity"
									:mouse-down-speed="500"
									:min="MIN_QTY"
									:max="setMaxQty"
									:button-class="'vnis__button bg-primary-alt'"
									:input-class="'vnis__input vnis-custom-input-width'"
									:integer-only="true"
									class="vnis-smaller col" />
							</div>
							<div
								:class="['xs','sm'].includes(windowWidth) ? 'text-right' : ''"
								class="col align-self-center mb-3">
								<div
									:class="disableAddBtn ? 'disabled' : ''"
									style="font-weight: 600;"
									class="btn btn-primary p-2 pointer custom-btn custom-primary-btn"
									@click="disableAddBtn ? null : addProduct(getProductSku)">
									<template v-if="addProductsLoading">
										<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
									</template>
									<template v-else>
										{{ translate('add_to_cart') }}
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Tabs -->
				<div
					:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'"
					class="row mx-auto container mb-5">
					<div class="col-12">
						<b-tabs
							id="product-details-tabs"
							v-model="tabIndex">
							<b-tab
								v-for="(tabInfo, index) in computedTabInfo"
								:key="index"
								:title="translate(index)">
								<!-- eslint-disable-next-line -->
								<p v-html="tabInfo" />
								<div
									v-if="mainProductData.sku === 'TK-VX'"
									class="text-center mt-3">
									<img
										:class="{ 'w-75': !['xs','sm'].includes(windowWidth) }"
										:src="ticketFlyerUrl"
										class="img-fluid mx-auto">
								</div>
							</b-tab>
						</b-tabs>
					</div>
				</div>
			</div>
			<!-- Related products -->
			<div v-if="$user.auth()">
				<div class="mx-auto container mt-5 mb-5">
					<hr>
				</div>
				<div v-if="mainProductLoading || relatedProductsLoading">
					<is-loading
						:loading="mainProductLoading || relatedProductsLoading"
						:loading-label="translate('loading')"
						:no-data-label="translate('data_not_found')" />
				</div>
				<div
					v-else
					:class="['xs','sm', 'md'].includes(windowWidth) ? ' px-3' : 'px-5'"
					class="row mx-auto container">
					<div class="col-12 text-center">
						<p class="text-muted h2 bold-text">
							{{ translate('related_products') }}
						</p>
					</div>
					<div
						class="py-4 w-100"
						:class="['xs','sm'].includes(windowWidth) ? 'scrolling-wrapper' : 'row'">
						<div
							v-for="(item, index) in relatedProductsData"
							:key="index"
							class="text-center my-3"
							:class="['xs','sm'].includes(windowWidth) ? 'd-inline-block' : 'col-md'">
							<img
								:src="item.image"
								:class="['xs','sm'].includes(windowWidth) ? 'img-fluid ' : 'img-max-width'"
								class="mx-auto mb-3 px-4 pointer"
								@click="$router.push({ name: 'PSProductDetails', params: { productName: item.product_name } })">
							<div class="mt-2">
								<p class="h6 text-muted bolder-title">
									{{ translate(item.code_name) }}
								</p>
								<p class="h4">
									{{ getProductPrice(item) }}
								</p>
								<!-- <p
									v-if="item.exchange"
									class="exchange-text h6">
									{{ item.exchange_price }}
								</p> -->
								<button
									type="button"
									:class="['md'].includes(windowWidth) ? ' w-75' : 'w-50'"
									class="btn btn-outline-primary btn-rounded mt-3"
									@click="$router.push({ name: 'PSProductDetails', params: { productName: item.product_name } })">
									{{ translate('see_more') }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import cookie from 'vue-cookie';
import NumberInputSpinner from 'vue-number-input-spinner';
import WindowSizes from '@/mixins/WindowSizes';
import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import { NOT_FOUND } from '@/settings/Errors';
import {
	NON_AUTH_HIDDEN_PRODUCTS,
	SORTED_PRODUCT_SIZES,
	PRODUCT_DEFAULT_PRICE,
	PRODUCT_OVERRIDE_PRICE,
	PRODUCT_MAX_QTY,
} from '@/settings/Products';
import {
	Store, Products as ProductsTranslations, Events,
} from '@/translations';
import Cart from '@/util/PhysicalStore/Cart';
import EventBus from '@/util/eventBus';
import Products from '@/util/PhysicalStore/Products';
import isLoading from '@/components/Loading';
import OptionSelect from '@/components/OptionSelect';
import ImageCarousel from '@/components/ProductImageCarousel';

export default {
	name: 'ProductDetails',
	messages: [Store, ProductsTranslations, Events],
	components: {
		OptionSelect,
		NumberInputSpinner,
		isLoading,
		ImageCarousel,
	},
	mixins: [WindowSizes],
	data() {
		return {
			NOT_FOUND,
			mainProduct: new Products(),
			relatedProducts: new Products(),
			comboProduct: new Products(),
			addProducts: new Cart(),
			alert: new this.$Alert(),
			SORTED_PRODUCT_SIZES,
			MIN_QTY,
			MAX_QTY,
			PRODUCT_MAX_QTY,
			selectedProduct: {
				quantity: MIN_QTY,
				color: '',
				size: '',
				format: '',
				event_type: '',
				presentations: '',
				flavor: '',
				combination: '',
				material_type: '',
				combo: [],
			},
			tabIndex: 0,
			ticketFlyerUrl: '',
			ticketFlyerImages: {
				en: 'https://velovita.s3-us-west-1.amazonaws.com/backoffice/assets/VelovitaXLR8.png',
				es: 'https://velovita.s3-us-west-1.amazonaws.com/backoffice/assets/VelovitaXLR8Es.png',
				ja: 'https://velovita.s3-us-west-1.amazonaws.com/backoffice/assets/VelovitaXLR8Ja.png',
			},
			outOfStockProducts: {},
			multipleOptions: [],
			requiredSelect: 0,
			comboLoading: false,
		};
	},
	computed: {
		mainProductData() {
			try {
				const { data } = this.mainProduct.data.response.data;
				let description = `${data.attributes.code_name}_description`;
				if (data.attributes.selectable) {
					data.attributes.configurations = {};
					description = data.attributes.selectable.default_description ?? description;
				}
				return {
					sku: data.attributes.sku,
					code_name: data.attributes.code_name,
					price: this.getProductPrice(data.attributes),
					image: data.attributes.image,
					presentation: `${data.attributes.code_name}_presentation`,
					children: data.attributes.children,
					thumbnail: data.attributes.thumbnail,
					exchange: data.attributes.exchange,
					exchange_price: data.attributes.exchange_price,
					description,
					selectable: data.attributes.selectable,
				};
			} catch (error) {
				return [];
			}
		},
		mainProductPrice() {
			try {
				if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price) {
					return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.price;
				}
				if (Object.keys(this.mainProductData).length > 0) {
					const children = this.mainProductData.children[Object.keys(this.getProductSku)[0]];
					if (children.eb_price) {
						return children.eb_price.price;
					}
					return children.price;
				}
				return this.mainProductData.price;
			} catch (e) {
				return this.mainProductData.price;
			}
		},
		mainHasExchange() {
			try {
				if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice) {
					return !!PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice;
				}
				if (Object.keys(this.mainProductData).length > 0) {
					return Object.keys(this.mainProductData.children).length ? this.mainProductData.children[Object.keys(this.getProductSku)[0]].exchange : this.mainProductData.exchange;
				}
				return false;
			} catch (e) {
				return false;
			}
		},
		mainExchangePrice() {
			try {
				if (PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice) {
					return PRODUCT_OVERRIDE_PRICE[this.mainProductData.sku]?.exchangePrice;
				}
				if (this.mainHasExchange) {
					return Object.keys(this.mainProductData.children).length ? this.mainProductData.children[Object.keys(this.getProductSku)[0]].exchange_price : this.mainProductData.exchange_price;
				}
				return '';
			} catch (e) {
				return '';
			}
		},
		mainProductLoading() {
			return !!this.mainProduct.data.loading || this.comboLoading;
		},
		getGroupToShow() {
			if (['Combo', 'Tri-Pack', 'Quad-Pack', 'Penta-Pack'].includes(this.$route.params.productName)) {
				return this.$route.params.productName;
			}
			return Object.keys(this.getProductSku)[0];
		},
		imgCarousel() {
			try {
				const { data } = this.mainProduct.data.response.data;
				let extraImages = data.attributes.extra_images;
				if (Array.isArray(extraImages) && extraImages.length === 0) {
					extraImages = {};
				}

				if (['Combo', 'Tri-Pack', 'Quad-Pack', 'Penta-Pack'].includes(data.attributes.decoded_name)) {
					extraImages[data.attributes.decoded_name] = [
						{
							image: data.attributes.image,
							thumbnail: data.attributes.thumbnail,
						},
					];
				}
				return extraImages;
			} catch (error) {
				return {};
			}
		},
		relatedProductsData() {
			try {
				const { data } = this.relatedProducts.data.response.data;
				let response = data.filter((obj) => (this.mainProductData.sku !== obj.attributes.sku)).slice(0, 3);
				response = response.map((obj) => {
					const newObj = {
						sku: obj.attributes.sku,
						code_name: obj.attributes.code_name,
						price: obj.attributes.price,
						image: obj.attributes.image,
						product_name: obj.attributes.decoded_name,
						exchange: obj.attributes.exchange,
						exchange_price: obj.attributes.exchange_price,
					};
					return newObj;
				});
				return response;
			} catch (error) {
				return [];
			}
		},
		relatedProductsLoading() {
			return !!this.relatedProducts.data.loading;
		},
		addProductsLoading() {
			return !!this.addProducts.data.loading;
		},
		getProductColors() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const colorInfo = data.attributes.configurations.color;
				const availableColors = Object.keys(colorInfo);
				if (availableColors.length === 0) {
					this.returnToStore();
				}
				return availableColors.map((color) => ({ value: colorInfo[color].code_name }));
			} catch (error) {
				return [];
			}
		},
		getProductSizes() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const colorInfo = data.attributes.configurations.color;
				const colorCodeName = Object.keys(colorInfo).find((color) => colorInfo[color].code_name === this.selectedProduct.color);
				const availableSizes = Object.keys(colorInfo[colorCodeName].configurations.size);
				this.sortSizes(availableSizes);
				if (availableSizes.length === 0) {
					this.returnToStore();
				}
				return availableSizes.map((value) => ({ value, text: this.translate(value.toUpperCase()) }));
			} catch (error) {
				return [];
			}
		},
		getProductFormats() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const formatsSku = data.attributes.configurations.format;
				let availableFormats = Object.keys(formatsSku);
				if (!this.$user.auth()) availableFormats = availableFormats.filter((item) => !['1003', '1004'].includes(item));
				if (availableFormats.length === 0) {
					this.returnToStore();
				}
				return availableFormats.map((value) => ({ value, text: this.translate(`${value}_format`) }));
			} catch (error) {
				return [];
			}
		},
		getEventType() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const availableEvents = Object.keys(data.attributes.configurations.event_type);
				if (availableEvents.length === 0) {
					this.returnToStore();
				}
				return availableEvents.map((value) => ({ value, text: this.translate(`${value}_event_type`) }));
			} catch (error) {
				return [];
			}
		},
		getPresentations() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const availablePresentations = Object.keys(data.attributes.configurations.presentations);
				if (availablePresentations.length === 0) {
					this.returnToStore();
				}
				return availablePresentations.map((value) => ({ value, text: this.translate(`${value}_presentations`) }));
			} catch (error) {
				return [];
			}
		},
		getFlavor() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const availableFlavors = Object.keys(data.attributes.configurations.flavor);
				if (availableFlavors.length === 0) {
					this.returnToStore();
				}
				return availableFlavors.map((value) => ({ value, text: this.translate(`${value}_flavor`) }));
			} catch (error) {
				return [];
			}
		},
		getMaterialTypes() {
			try {
				const { data } = this.mainProduct.data.response.data;
				return Object.keys(data.attributes.configurations.material_type).map((value) => ({ value, text: this.translate(`${value}_material_type`) }));
			} catch (error) {
				return [];
			}
		},
		getCombination() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const availableCombinations = Object.keys(data.attributes.configurations.combination);
				if (availableCombinations.length === 0) {
					this.returnToStore();
				}
				return availableCombinations.map((value) => ({ value, text: this.translate(`${value}_combination`) }));
			} catch (error) {
				return [];
			}
		},
		getProductSku() {
			try {
				const { data } = this.mainProduct.data.response.data;
				let sku = [this.mainProductData.sku];
				if (this.selectedProduct.color) {
					const colorInfo = data.attributes.configurations.color;
					const selectedColor = Object.keys(colorInfo).find((color) => colorInfo[color].code_name === this.selectedProduct.color);
					sku.push(selectedColor);
				}
				if (this.selectedProduct.size) {
					sku.push(this.selectedProduct.size);
				}
				if (this.selectedProduct.format) {
					sku.push(this.selectedProduct.format);
				}
				if (this.selectedProduct.event_type) {
					sku.push(this.selectedProduct.event_type);
				}
				if (this.selectedProduct.presentations) {
					sku.push(this.selectedProduct.presentations);
				}
				if (this.selectedProduct.flavor) {
					sku.push(this.selectedProduct.flavor);
				}
				if (this.selectedProduct.material_type) {
					sku.push(this.selectedProduct.material_type);
				}
				if (this.selectedProduct.combination) {
					sku.push(this.selectedProduct.combination);
				}
				if (this.selectedProduct.combo.length) {
					const skus = {};
					this.selectedProduct.combo.forEach((combo, index) => {
						if (combo) {
							skus[[this.multipleOptions[index].sku, combo].join('-')] = this.selectedProduct.quantity;
						}
					});
					return skus;
				}
				sku = sku.join('-');
				return { [sku]: this.selectedProduct.quantity };
			} catch (error) {
				return [];
			}
		},
		productOutOfStock() {
			try {
				const options = this.getProductOptions(this.getProductSku);
				return options.outOfStock;
			} catch (error) {
				return false;
			}
		},
		setMaxQty() {
			try {
				return this.PRODUCT_MAX_QTY[this.selectedProduct.format] ? this.PRODUCT_MAX_QTY[this.selectedProduct.format] : this.MAX_QTY;
			} catch (error) {
				return this.MAX_QTY;
			}
		},
		isForIndividualPurchase() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const options = this.getProductOptions(this.getProductSku);
				return options.individualPurchase || data.attributes.individual_purchase;
			} catch (error) {
				return false;
			}
		},
		shippingDateAlert() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const options = this.getProductOptions(this.getProductSku);

				return options.customShippingDate || data.attributes.custom_shipping_date;
			} catch (error) {
				return false;
			}
		},
		productsMissingForCombo() {
			try {
				return this.requiredSelect - this.selectedProduct.combo.reduce((accumulator, item) => accumulator + (typeof item !== 'undefined'), 0);
			} catch (error) {
				return 0;
			}
		},
		isComboComplete() {
			return this.productsMissingForCombo === 0;
		},
		isCombo() {
			return this.requiredSelect > 1;
		},
		disableAddBtn() {
			return this.addProductsLoading || (this.isCombo && !this.isComboComplete) || this.hasSelectedOutOfStock;
		},
		hasSelectedOutOfStock() {
			let hasSelectedOutOfStock = false;
			try {
				this.selectedProduct.combo.forEach((combo, index) => {
					this.multipleOptions[index].options.forEach((option) => {
						if (option.outOfStock && option.value === combo) {
							hasSelectedOutOfStock = true;
						}
					});
				});
			} catch (error) {
				return hasSelectedOutOfStock;
			}
			return hasSelectedOutOfStock;
		},
		comboData() {
			const { variants, discount_amount: discount } = this.mainProductData.selectable;
			// eslint-disable-next-line no-restricted-syntax
			for (const variant of variants) {
				const selected = this.multipleOptions.filter((_, i) => this.selectedProduct.combo[i] !== undefined).map((opt) => opt.category);
				if (selected.length === variant.combination.length && selected.every((category) => variant.combination.includes(category))) {
					const price = selected.reduce((amount, _, i, categories) => amount + this.multipleOptions.find((opt) => opt.category === categories[i]).price, 0) - discount;
					return {
						title: variant.title,
						description: variant.description,
						price: `$${price.toFixed(2)} USD`,
					};
				}
			}
			return {};
		},
		computedTitle() {
			const data = this.mainProductData;
			let { code_name: title } = data;
			const customTranslateKey = this.customTranslateKey(data.sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.title) {
				title = customTranslateKey.title;
			}
			return this.translate(this.isCombo ? this.comboData.title ?? title : title);
		},
		computedPresentation() {
			const data = this.mainProductData;
			let { presentation } = data;
			const customTranslateKey = this.customTranslateKey(data.sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.presentation) {
				presentation = customTranslateKey.presentation;
			}
			return this.translate(presentation);
		},
		computedPrice() {
			return this.isCombo ? this.comboData.price ?? this.rangePrice : this.mainProductPrice;
		},
		computedPriceInfo() {
			const { sku } = this.mainProductData;
			const customTranslateKey = this.customTranslateKey(sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.priceInfo) {
				return this.translate(customTranslateKey.priceInfo);
			}
			if (Object.keys(this.monthlyPaymentInfo).length > 0) {
				return this.monthlyPaymentInfo.installments.qty > 0 ? this.translate('velovita_xlr8_2022_installments_info', {
					upfront: this.monthlyPaymentInfo.upfront,
					installments: this.monthlyPaymentInfo.installments.qty,
					installment_amount: this.monthlyPaymentInfo.installments.amount,
					installments_text: this.translate(this.monthlyPaymentInfo.installments.qty > 1 ? 'installments' : 'installment'),
				}) : '';
			}
			return '';
		},
		computedTabInfo() {
			const data = this.mainProductData;
			let { description } = data;
			const customTranslateKey = this.customTranslateKey(data.sku);
			if (typeof customTranslateKey !== 'undefined' && customTranslateKey.description) {
				if (customTranslateKey.description.translated) {
					return { details: customTranslateKey.description.translated };
				}
				description = customTranslateKey.description;
			}
			return { details: this.translate(this.isCombo ? this.comboData.description ?? description : description) };
		},
		rangePrice() {
			const { variants, products, discount_amount: discount } = this.mainProductData.selectable;
			const prices = variants
				.filter((variant) => variant.combination.every((combo) => this.multipleOptions.some((opt) => opt.category === combo)))
				.filter((variant) => variant.combination.filter((combo) => !(products[this.country] ?? products.default).includes(combo)).length === 0)
				.map((variant) => variant.combination.reduce((amount, _, i, categories) => amount + (this.multipleOptions.find((opt) => opt.category === categories[i])?.price ?? 0), 0) - discount)
				.filter((value, i, values) => values.indexOf(value) === i)
				.sort((a, b) => a - b);
			return prices.length > 0 ? `$${prices[0].toFixed(2).concat(prices.length > 1 ? ` - $${prices[prices.length - 1].toFixed(2)}` : '')} USD` : this.mainProductPrice;
		},
		minPurchaseAlert() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const options = this.getProductOptions(this.getProductSku);

				return options.minPurchaseQty.restricted || data.attributes.minimun_purchase_qty.restricted;
			} catch (error) {
				return false;
			}
		},
		minPurchaseAlertMsg() {
			try {
				const { data } = this.mainProduct.data.response.data;
				const options = this.getProductOptions(this.getProductSku);

				if (options.minPurchaseQty.restricted) {
					return options.minPurchaseQty.message_code_name;
				}
				return this.translate(data.attributes.minimun_purchase_qty.message_code_name, {
					qty: data.attributes.minimun_purchase_qty.min_qty,
				});
			} catch (error) {
				return '';
			}
		},
		monthlyPaymentInfo() {
			try {
				return this.mainProductData.children[Object.keys(this.getProductSku)[0]].monthly_payment_info;
			} catch (error) {
				return {};
			}
		},
	},
	watch: {
		async country() {
			this.initProductInfo();
		},
		setMaxQty(value) {
			if (this.selectedProduct.quantity > value) this.selectedProduct.quantity = value;
		},
		language(value) {
			this.ticketFlyerUrl = this.ticketFlyerImages[value];
		},
	},
	mounted() {
		this.initProductInfo();
		this.ticketFlyerUrl = this.ticketFlyerImages[this.language];
	},
	methods: {
		initProductInfo() {
			const { productName } = this.$route.params;

			// Temporarily hide combos in Canada due to wrong totals behavior in Magento
			if (this.country === 'CA' && ['Combo', 'Tri-Pack', 'Quad-Pack', 'Quad-Pack-Tuun', 'Penta-Pack'].includes(this.$route.params.productName)) {
				return this.$router.push({ name: 'PSStore' });
			}

			return this.mainProduct.getProductDetails(productName).then((response) => {
				const { category_code: categoryCode, sku, selectable } = response.attributes;

				if (!this.$user.auth() && NON_AUTH_HIDDEN_PRODUCTS.includes(sku)) {
					this.$router.push({ name: 'PSStore' });
				}

				// For combos
				if (selectable) {
					this.comboLoading = true;
					this.requiredSelect = selectable.required_select;
					const products = selectable.products[this.country] ?? selectable.products.default;
					Promise.allSettled(products.map((name) => this.comboProduct.getProductDetails(name)))
						.then((allResponses) => {
							this.multipleOptions = allResponses.filter((res) => res.status === 'fulfilled').map((res) => res.value).map((res, i) => ({
								sku: res.attributes.sku,
								category: products[i],
								price: res.attributes.price_amount,
								title: this.translate(res.attributes.code_name),
								options: Object.keys(res.attributes.configurations.flavor).map((value) => ({ value, text: this.translate(`${value}_flavor`), outOfStock: res.attributes.children[`${res.attributes.sku}-${value}`].out_of_stock })),
							}));
						})
						.finally(() => {
							this.comboLoading = false;
						});
				}
				// End for combos

				[this.selectedProduct.color] = [this.getProductColors[0]?.value];
				[this.selectedProduct.size] = [this.getProductSizes[0]?.value];
				[this.selectedProduct.format] = [this.getProductFormats[0]?.value];
				[this.selectedProduct.event_type] = [this.getEventType[0]?.value];
				[this.selectedProduct.presentations] = [this.getPresentations[0]?.value];
				[this.selectedProduct.flavor] = [this.getFlavor[0]?.value];
				[this.selectedProduct.combination] = [this.getCombination[0]?.value];
				[this.selectedProduct.material_type] = [this.getMaterialTypes[0]?.value];
				return this.$user.auth() ? this.relatedProducts.getProducts(this.getStoredCountry(), categoryCode) : [];
			}).catch((error) => {
				if (this.NOT_FOUND.includes(error.status)) this.$router.push({ name: 'PSStore' });
			});
		},
		sortSizes(sizes) {
			sizes.sort((a, b) => SORTED_PRODUCT_SIZES.indexOf(a) - SORTED_PRODUCT_SIZES.indexOf(b));
		},
		getProductOptions(products) {
			let image = this.mainProductData.thumbnail;
			let outOfStock = false;
			let individualPurchase = false;
			let customShippingDate = false;
			let minPurchaseQty = {};
			if (Object.keys(this.mainProductData.children).length) {
				const { children } = this.mainProductData;
				Object.keys(products).forEach((sku) => {
					image = children[sku].thumbnail;
					outOfStock = children[sku].out_of_stock;
					individualPurchase = children[sku].individual_purchase;
					customShippingDate = children[sku].custom_shipping_date;
					minPurchaseQty = children[sku].minimun_purchase_qty;
				});
			}
			const options = {
				image,
				outOfStock,
				individualPurchase,
				customShippingDate,
				minPurchaseQty,
			};
			return options;
		},
		async addProduct(products) {
			if (!this.addProductsLoading) {
				try {
					await this.addProducts.addCartProducts(cookie.get('cart_id'), { products });
					EventBus.$emit('updateLayoutStoreTotals');
				} catch (e) {
					if (typeof this.addProducts.errors.errors.products !== 'undefined') {
						let response = '';
						this.addProducts.errors.errors.products.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 4000 });
					} else {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				}
			}
		},
		getProductPrice(product) {
			const { price, sku } = product;

			if (PRODUCT_OVERRIDE_PRICE[sku]) {
				return PRODUCT_OVERRIDE_PRICE[sku].price;
			}

			if (price !== '$0.00 USD') {
				return price;
			}

			if (PRODUCT_DEFAULT_PRICE[sku]) {
				return PRODUCT_DEFAULT_PRICE[sku].price;
			}

			return price;
		},
		returnToStore() {
			this.$router.push({ name: 'PSStore' });
		},
		installmentsInfo() {
			return this.monthlyPaymentInfo.installments.qty > 0 ? this.translate('installments_info', {
				monthly: this.monthlyPaymentInfo.monthly,
				upfront: this.monthlyPaymentInfo.upfront,
				installments: this.monthlyPaymentInfo.installments.qty,
				installment_amount: this.monthlyPaymentInfo.installments.amount,
				installments_text: this.translate(this.monthlyPaymentInfo.installments.qty > 1 ? 'installments' : 'installment'),
			}) : '';
		},
		customTranslateKey(sku) {
			switch (sku) {
			case 'TK-VX22':
				switch (this.selectedProduct.event_type) {
				case 'SI':
					return {
						presentation: 'velovita_xlr8_2022_presentation_1_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_si_description', {
								price: this.computedPrice,
								installments_info: this.installmentsInfo(),
							}),
						},
					};
				case 'SE':
					return {
						presentation: 'velovita_xlr8_2022_presentation_1_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_se_description', {
								price: this.computedPrice,
								installments_info: this.installmentsInfo(),
							}),
						},
					};
				case 'CO':
					return {
						presentation: 'velovita_xlr8_2022_presentation_2_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_co_description', {
								price: this.computedPrice,
								installments_info: this.installmentsInfo(),
							}),
						},
					};
				case 'CE':
					return {
						presentation: 'velovita_xlr8_2022_presentation_2_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_ce_description', {
								price: this.computedPrice,
								installments_info: this.installmentsInfo(),
							}),
						},
					};
				case 'NR':
					return {
						presentation: 'velovita_xlr8_2022_presentation_1_ticket',
						description: {
							translated: this.translate('velovita_xlr8_2022_nr_description', {
								price: this.computedPrice,
							}),
						},
					};
				default: return undefined;
				}
			default: return undefined;
			}
		},
	},
};
</script>
<style scoped>
.h-55 {
	height: 55px;
}
.h-80 {
	height: 80px;
}
.custom-btn.custom-primary-btn{
	width: 150px;
	border-radius: 25px;
}
.img-max-width{
	max-width: 250px;
}
</style>
<style>
#theme .vnis-custom-input-width{
	width: 40px;
}
.btn-disabled.disabled{
	background: #777777b5;
    border-color: rgba(130, 130, 130, 0.709804);
    color: #FFFFFF;
}
.btn-disabled.disabled:hover{
	background: #777777b5;
    border-color: rgba(130, 130, 130, 0.709804);
    color: #FFFFFF;
}
.bolder-title {
	-webkit-text-stroke: 1.3px;
	font-size: 1.2em;
}
#theme .btn-rounded {
	border-radius: 25px;
}
/* Tabs */
#product-details-tabs ul {
	border-bottom: 2px solid #c8ced3;
}
#product-details-tabs li a{
	padding-right: 1em;
	padding-bottom: .5em;
	margin-right: 1.5em;
}
#product-details-tabs li a.active {
	border-bottom: 3px solid #eb772f;
	border-width: thick;
}
#product-details-tabs .tab-content {
	padding: 2em 0em 2em 0em;
	border: none;
}
</style>
